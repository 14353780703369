<form [formGroup]="commitmentForm" class="mt-3 mb-5" id="commitmentForm">
    <input formControlName="datasource" type="hidden">
    <div class="form-group">
        <label class="col-form-label asterisk" for="commitmentTitle">Commitment Title 
            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.commitmentTitle}}"></i>
        </label>
        <div>
            <input id="commitmentTitle" formControlName="commitmentTitle" type="text" class="form-control"
                (ngModelChange)="onFieldChange({ name: 'commitmentTitle', value: $event })">
            <span class="field-error-message" *ngIf="getValidationError('commitmentTitle')">{{getValidationError('commitmentTitle')}}</span>
        </div>
    </div>
    <div class="form-group">
        <label class="col-form-label asterisk" for="commitmentDescription">Commitment Description 
            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.commitmentDescription}}"></i>
        </label>
        <div>
            <textarea id="commitmentDescription" formControlName="commitmentDescription" class="form-control" rows="5"></textarea>
            <span class="field-error-message" *ngIf="getValidationError('commitmentDescription')">{{getValidationError('commitmentDescription')}}</span>
        </div>
    </div>

    <!-- Client Details -->
    <div class="card mb-3 mt-3">
        <div class="card-header">
            <b>Client Details</b>
        </div>
        <div class="card-body">
            <div class="row">
                <!--left side -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="col col-form-label asterisk" for="clientName">Client Name</label>
                        <div class="col">
                            <input id="clientName" formControlName="clientName" type="text" class="form-control">
                            <span class="field-error-message" *ngIf="getValidationError('clientName')">{{getValidationError('clientName')}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="relationshipManagerName">Relationship Manager/ Account Executive</label>
                        <div class="col">
                            <input id="relationshipManagerName" formControlName="relationshipManagerName" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="newRM">New Relationship Manager</label>
                        <div class="col">
                            <input id="newRM" formControlName="newRM" type="text" class="form-control">
                        </div>
                    </div>
                </div>
                <!--right side -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="col col-form-label asterisk" for="clientDunsNumber">Client Identifier (DUNS ID)</label>
                        <div class="col">
                            <input id="clientDunsNumber" formControlName="clientDunsNumber" type="text" class="form-control">
                            <span class="field-error-message" *ngIf="getValidationError('clientDunsNumber')">{{getValidationError('clientDunsNumber')}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="clientTemperature">Client Temperature 
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.clientTemperature}}"></i>
                        </label>
                        <div class="col">
                            <input id="clientTemperature" formControlName="clientTemperature" type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Commitment Details -->
    <div class="card mb-3">
        <div class="card-header">
            <b>Commitment Details</b>
        </div>
        <div class="card-body">
            <div class="row">
                <!--left side -->
                <div class="col-6">
                    <div class="form-group" *ngIf="getDisplayRuleByKey('buResponsibleForDeliveryIntakes')">
                        <label class="col col-form-label asterisk" for="buResponsibleForDeliveryIntakes">BU Responsible for Delivery 
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.buResponsibleForDeliveryIntakes}}"></i>
                        </label>
                        <div class="col">
                            <ng-select id="buResponsibleForDeliveryIntakes"
                                formControlName="buResponsibleForDeliveryIntakes"
                                [items]="buResponsibleForDeliveryOptions" [virtualScroll]="true"
                                [searchFn]="searchBuResponsibleForDeliveryFunc"
                                [clearOnBackspace]="false" placeholder="Type to search" class="ng-select" [multiple]="true"
                                [compareWith]="compareBuesponsibleForDeliveryIntake">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                let-index="index">
                                    <span title="{{itemTemp.businessUnitTitle}}"> {{itemTemp.businessUnitTitle}} </span>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items">
                                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                        <span class="ng-value-label" title="{{item.businessUnitTitle}}">{{item.businessUnitTitle}} </span>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('buResponsibleForDeliveryIntakes')">{{getValidationError('buResponsibleForDeliveryIntakes')}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('organizations')">
                        <label class="col col-form-label asterisk" for="organizations">BU Responsible for Delivery 
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.organizations}}"></i>
                        </label>
                        <div class="col">
                            <ng-select 
                                id="organizations"
                                formControlName="organizations"
                                [items]="organizationOptions" [virtualScroll]="true"
                                [clearOnBackspace]="false" placeholder="Type to search"
                                class="ng-select" [multiple]="true" [compareWith]="compareOrganization"
                                [searchFn]="searchOrganizationFunc"
                                (change)="onFieldChange({ name: 'organizations', value: $event })">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.title}}"> {{itemTemp.title}} </span>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items">
                                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                        <span class="ng-value-label" title="{{item.title}}">{{item.title}} </span>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('organizations')">{{getValidationError('organizations')}}</span>
                        </div>
                    </div>
                    <!-- <input formControlName="lineOfBusinesses" type="hidden" class="form-control"> -->
                    <div class="form-group">
                        <label class="col col-form-label asterisk" for="commitmentType">Commitment Type 
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.commitmentType}}"></i>
                        </label>
                        <div class="col">
                            <ng-select
                                id="commitmentType"
                                formControlName="commitmentType"
                                [items]="commitmentTypeOptions" [virtualScroll]="true" 
                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                bindValue="value" class="ng-select"
                                (change)="onFieldChange({ name: 'commitmentType', value: $event })">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('commitmentType')">{{getValidationError('commitmentType')}}</span>
                        </div>
                    </div>
                    <div class="form-group mb-3" *ngIf="getDisplayRuleByKey('contractualPenalties')">
                        <label class="col col-form-label" for="contractualPenalties">Estimated Credits Incurred</label>
                        <div class="col">
                            <input id="contractualPenalties" formControlName="contractualPenalties" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="commitmentDate">Commitment Date
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.commitmentDate}}"></i>
                        </label>
                        <div class="col">
                            <input id="commitmentDate" formControlName="commitmentDate" type="date"
                            [ngClass]="generateInputClass('commitmentDate')"
                            class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="col col-form-label asterisk" for="products">Product/s
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.products}}"></i>
                        </label>
                        <div class="col">
                            <ng-select 
                                id="products"
                                formControlName="products"
                                [items]="productOptions" [virtualScroll]="true"
                                [clearOnBackspace]="false" placeholder="Type to search"
                                class="ng-select" [multiple]="true" [compareWith]="compareProduct"
                                [searchFn]="searchProductFunction"
                                (change)="onFieldChange({ name: 'products', value: $event })">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.productTitle}}">{{itemTemp.productTitle}} </span>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items">
                                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                        <span class="ng-value-label" title="{{item.productTitle}}">{{item.productTitle}} </span>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('products')">{{getValidationError('products')}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label asterisk" for="commitmentSubType">Obligation Type
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.commitmentSubType}}"></i>
                        </label>
                        <div class="col">
                            <ng-select id="commitmentSubType" formControlName="commitmentSubType"
                                [items]="commitmentSubTypeOptions" [virtualScroll]="true" 
                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                (ngModelChange)="onFieldChange({ name: 'commitmentSubType', value: $event })"
                                bindValue="value" class="ng-select">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('commitmentSubType')">{{getValidationError('commitmentSubType')}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('contractualPenaltyDescription')">
                        <label class="col col-form-label asterisk" for="contractualPenaltyDescription">Estimated Credits Incurred Reason</label>
                        <div class="col">
                            <textarea id="contractualPenaltyDescription" formControlName="contractualPenaltyDescription" class="form-control" rows="3"></textarea>
                        </div>
                        <span class="field-error-message" *ngIf="getValidationError('contractualPenaltyDescription')">{{getValidationError('contractualPenaltyDescription')}}</span>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="commitmentAuthorizedBy">Commitment Authorized By
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.commitmentAuthorizedBy}}"></i>
                        </label>
                        <div class="col">
                            <input id="commitmentAuthorizedBy" formControlName="commitmentAuthorizedBy" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="col col-form-label" for="rmAdditionalComments">RM Additional Comments </label>
                        <div class="col">
                            <textarea id="rmAdditionalComments" formControlName="rmAdditionalComments" class="form-control" rows="5"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Commitment Status -->
    <div class="card mb-3">
        <div class="card-header">
            <b>Stage 
                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.status}}"></i>
            </b>
        </div>
        <div class="card-body">
            <div class="row">
                <!--left side -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="col col-form-label asterisk" for="status">Stage</label>
                        <div class="col">
                            <ng-select
                                id="status"
                                formControlName="status"
                                [items]="statusOptions" [virtualScroll]="true"
                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                bindValue="value" class="ng-select"
                                (change)="onFieldChange({ name: 'status', value: $event })">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label" title="{{getSelectedLabel(statusOptions, item.value)}}">
                                        {{getSelectedLabel(statusOptions, item.value)}}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('status')">{{getValidationError('status')}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="originalGoLiveDate">Original Planned Go Live Date
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.originalGoLiveDate}}"></i>
                        </label>
                        <div class="col">
                            <input id="originalGoLiveDate" type="date" formControlName="originalGoLiveDate" 
                            [ngClass]="generateInputClass('originalGoLiveDate')"
                            class="form-control">
                        </div>                        
                    </div>
                    <div class="form-group">
                        <label class="col col-form-label" for="plannedGoLiveDate" *ngIf="!isFieldRequired('plannedGoLiveDate')">Planned Go Live Date
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.plannedGoLiveDate}}"></i>
                        </label>
                        <label class="col col-form-label asterisk" for="plannedGoLiveDate" *ngIf="isFieldRequired('plannedGoLiveDate')">Planned Go Live Date
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.plannedGoLiveDate}}"></i>
                        </label>
                        <div class="col">
                            <input id="plannedGoLiveDate" type="date" formControlName="plannedGoLiveDate" 
                            [ngClass]="generateInputClass('plannedGoLiveDate')"
                            class="form-control"
                            (ngModelChange)="onFieldChange({ name: 'plannedGoLiveDate', value: $event })">
                            <span class="field-error-message" *ngIf="getValidationError('plannedGoLiveDate')">{{getValidationError('plannedGoLiveDate')}}</span>
                        </div>                        
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('selectedPlannedGoLiveDateChangeReason')">
                        <label class="col col-form-label asterisk" for="selectedPlannedGoLiveDateChangeReason">Planned Go Live Date Change Reason
                            <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.selectedPlannedGoLiveDateChangeReason}}"></i>
                        </label>
                        <div class="col">
                            <ng-select 
                                id="selectedPlannedGoLiveDateChangeReason"
                                formControlName="selectedPlannedGoLiveDateChangeReason"
                                [items]="plannedGoLiveDateChangeReasonOptions" [virtualScroll]="true" 
                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                bindValue="value" class="ng-select">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label" title="{{getSelectedLabel(plannedGoLiveDateChangeReasonOptions, item.value)}}">
                                        {{getSelectedLabel(plannedGoLiveDateChangeReasonOptions, item.value)}}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('selectedPlannedGoLiveDateChangeReason')">{{getValidationError('selectedPlannedGoLiveDateChangeReason')}}</span>
                        </div>
                    </div>
                    <div class="form-group mb-3" *ngIf="getDisplayRuleByKey('plannedGoLiveDateChangeReasonOther')">
                        <label class="col col-form-label asterisk" for="plannedGoLiveDateChangeReasonOther">Planned Go Live Date Change Reason (Other)</label>
                        <div class="col">
                            <textarea id="plannedGoLiveDateChangeReasonOther" formControlName="plannedGoLiveDateChangeReasonOther" class="form-control" rows="3"
                            (ngModelChange)="onFieldChange({ name: 'plannedGoLiveDateChangeReasonOther', value: $event })"></textarea>
                            <span class="field-error-message" *ngIf="getValidationError('plannedGoLiveDateChangeReasonOther')">{{getValidationError('plannedGoLiveDateChangeReasonOther')}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('liveDateChangeReasonRM')">
                        <label class="col col-form-label asterisk" for="liveDateChangeReasonRM">Go Live Date Change Reason</label>
                        <div class="col">
                            <textarea id="liveDateChangeReasonRM" formControlName="liveDateChangeReasonRM" class="form-control" rows="3"></textarea>
                            <span class="field-error-message" *ngIf="getValidationError('liveDateChangeReasonRM')">{{getValidationError('liveDateChangeReasonRM')}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('plannedGoLiveDateChangeReason')">
                        <label class="col col-form-label" for="plannedGoLiveDateChangeReason">Planned Go Live Date Change Reason</label>
                        <div class="col">
                            <textarea id="plannedGoLiveDateChangeReason" formControlName="plannedGoLiveDateChangeReason" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox col">
                            <input type="checkbox" formControlName="isCommitmentBreached" id="isCommitmentBreached">
                            <label class="col-form-label pl-4" for="isCommitmentBreached">Is Commitment Missed? 
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.isCommitmentBreached}}"></i>
                            </label>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('breachedReason')">
                        <label class="col col-form-label" for="breachedReason">Missed Commitment Reason</label>
                        <div class="col">
                            <textarea id="breachedReason" formControlName="breachedReason" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group" *ngIf="getDisplayRuleByKey('onHoldStakeholder')">
                        <label class="col col-form-label asterisk" for="onHoldStakeholder">On Hold Stakeholder</label>
                        <div class="col">
                            <ng-select
                                id="onHoldStakeholder"
                                formControlName="onHoldStakeholder"
                                [items]="onHoldStakeholderOptions" [virtualScroll]="true"
                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                bindValue="value" class="ng-select">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label" title="{{getSelectedLabel(onHoldStakeholderOptions, item.value)}}">
                                        {{getSelectedLabel(onHoldStakeholderOptions, item.value)}}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('onHoldStakeholder')">{{getValidationError('onHoldStakeholder')}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('onHoldReason')">
                        <label class="col col-form-label asterisk" for="onHoldReason">On Hold Reason</label>
                        <div class="col">
                            <ng-select
                                id="onHoldReason"
                                formControlName="onHoldReason"
                                [items]="onHoldReasonOptions" [virtualScroll]="true"
                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                bindValue="value" class="ng-select">
                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                    let-index="index">
                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label" title="{{getSelectedLabel(onHoldReasonOptions, item.value)}}">
                                        {{getSelectedLabel(onHoldReasonOptions, item.value)}}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="field-error-message" *ngIf="getValidationError('onHoldReason')">{{getValidationError('onHoldReason')}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="getDisplayRuleByKey('onHoldReasonDescription')">
                        <label class="col col-form-label" for="onHoldReasonDescription">Please explain the on hold reason.</label>
                        <div class="col">
                            <textarea id="onHoldReasonDescription" formControlName="onHoldReasonDescription" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Fulfillment Details (Clarity) -->
    <div class="card mb-3">
        <div class="card-header">
            <i (click)="isShowFulfillmentDetails = !isShowFulfillmentDetails" data-title="{{getFulfillmentDetailsTitle()}}" [ngClass]="isShowFulfillmentDetails ? 'collapse-icon fa fa-minus-square-o text-muted mr-1' : 'collapse-icon fa fa-plus-square-o text-muted mr-1'"
                data-toggle="collapse" data-target="#collapseFulfillmentDetails" aria-expanded="false" aria-controls="collapseFulfillmentDetails"></i>
            <b>{{getFulfillmentDetailsTitle()}}
                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.fulfillmentDetails}}"></i>
            </b>
        </div>
        <div class="collapse" id="collapseFulfillmentDetails">
            <div class="card card-body">
                <div class="row" *ngIf="getDisplayRuleByKey('fetchClarityDetailsPanel')">
                    <!--left side -->
                    <div class="col-6">
                        <!--<div class="form-group">
                            <div class="checkbox col">
                                <input type="checkbox" formControlName="doYouWantToMapTheCommitmentWithClarityProject" 
                                    id="doYouWantToMapTheCommitmentWithClarityProject"
                                    (change)="onCanMapTheCommitmentWithClarityProjectChange()">
                                <label class="col-form-label pl-4" for="doYouWantToMapTheCommitmentWithClarityProject">Do you want to map the commitment with an existing Clarity Project? </label>
                            </div>
                        </div>-->
                        <div class="form-group" *ngIf="getDisplayRuleByKey('searchClarityProjectNumber')">
                            <label class="col col-form-label">Project number/ID
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.searchClarityProjectNumber}}"></i>
                            </label>
                            <div class="col">
                                <input type="text" formControlName="searchClarityProjectNumber" class="form-control" (keyup)="onSearchClarityProjectNumberChange($event)">
                            </div>
                        </div>
                        <div class="form-group" *ngIf="getDisplayRuleByKey('fetchClarityDetailsButton')">
                            <div class="col">
                                <button type="button" class="btn btn-primary btn-md buttonclick" (click)="fetchClarityDetails()">Get Clarity Projects</button>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="getDisplayRuleByKey('clarityErrorMessage')">
                            <div class="col">
                                <span style="color: red;">{{this.apiClarityDataErrorMessage}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <input type="hidden" formControlName="ClientTotalOfDaysChanged">
                        <input type="hidden" formControlName="ClientTotalOfDaysChangedCount">
                        <input type="hidden" formControlName="FiservTotalOfDaysChanged">
                        <input type="hidden" formControlName="FiservTotalOfDaysChangedCount">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col col-form-label" for="projectNumber">Project number/ID</label>
                            <div class="col">
                                <input *ngIf="getDisplayRuleByKey('clarityProjectNumber')" id="projectNumber" 
                                    formControlName="projectNumber" type="text" class="form-control"
                                    (ngModelChange)="onFieldChange({ name: 'projectNumber', value: $event })">
                                <input *ngIf="!getDisplayRuleByKey('clarityProjectNumber')" type="text" class="form-control" disabled>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="workStatus">Work Status</label>
                            <div class="col">
                                <input id="workStatus" formControlName="workStatus" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="progress">Progress</label>
                            <div class="col">
                                <input id="progress" formControlName="progress" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="statusUpdate">Status Update</label>
                            <div class="col">
                                <input id="statusUpdate" formControlName="statusUpdate" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="projectManager">Project Manager</label>
                            <div class="col">
                                <input id="projectManager" formControlName="projectManager" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="resourceManagerLevel4">Resource Manager Level4</label>
                            <div class="col">
                                <input id="resourceManagerLevel4" formControlName="resourceManagerLevel4" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="dateStatus">Date Status</label>
                            <div class="col">
                                <input id="dateStatus" formControlName="dateStatus" type="text" class="form-control"
                                (ngModelChange)="onFieldChange({ name: 'dateStatus', value: $event })">
                            </div>
                        </div>
                        <input type="hidden" formControlName="totalPlannedEffortHours">
                        <input type="hidden" formControlName="plannedBenefitAmount">
                        <input type="hidden" formControlName="plannedBenefitCurrentYear">
                        <input type="hidden" formControlName="lastDateChangeCommentary">
                        <input type="hidden" formControlName="deliveryConfirmation">
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col col-form-label" for="projectStatus">Project Status</label>
                            <div class="col">
                                <input id="projectStatus" formControlName="projectStatus" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="clarityStatus">Clarity Status</label>
                            <div class="col">
                                <input id="clarityStatus" formControlName="clarityStatus" type="text" class="form-control">
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label class="col col-form-label" for="plannedCommittedStartDate">Planned/ Committed Start Date
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.plannedCommittedStartDate}}"></i>
                            </label>
                            <div class="col">
                                <input id="plannedCommittedStartDate" formControlName="plannedCommittedStartDate" type="date"
                                [ngClass]="generateInputClass('plannedCommittedStartDate')"                                
                                class="form-control">                                
                            </div>
                        </div>
                        <div class="form-group" *ngIf="getDisplayRuleByKey('plannedCommittedDeliveryDate')">
                            <label class="col col-form-label" for="plannedCommittedDeliveryDate">Planned/ Committed Delivery Date
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.plannedCommittedDeliveryDate}}"></i>
                            </label>
                            <div class="col">
                                <input id="plannedCommittedDeliveryDate" formControlName="plannedCommittedDeliveryDate" type="date" 
                                class="form-control"
                                [ngClass]="generateInputClass('plannedCommittedDeliveryDate')"
                                (change)="onFieldChange({ name: 'plannedCommittedDeliveryDate', value: $event })">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="displayPlannedDeliveryDate">Planned/ Committed Delivery Date
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.plannedCommittedDeliveryDate}}"></i>
                            </label>
                            <div class="col">
                                <input id="displayPlannedDeliveryDate" formControlName="displayPlannedDeliveryDate" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="statusUpdateDate">Status Update Date
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.statusUpdateDate}}"></i>
                            </label>
                            <div class="col">
                                <input id="statusUpdateDate" formControlName="statusUpdateDate"
                                [ngClass]="generateInputClass('statusUpdateDate')"
                                type="date" class="form-control" value="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="mc">MC</label>
                            <div class="col">
                                <input id="mc" formControlName="mc" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group" *ngIf="getDisplayRuleByKey('mc1')">
                            <label class="col col-form-label" for="mc1">MC1</label>
                            <div class="col">
                                <input id="mc1" formControlName="mc1" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="deliveryOwner">Delivery Owner</label>
                            <div class="col">
                                <input id="deliveryOwner" formControlName="deliveryOwner" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="resourceManagerLevel5">Resource Manager Level5</label>
                            <div class="col">
                                <input id="resourceManagerLevel5" formControlName="resourceManagerLevel5" type="text" class="form-control">
                            </div>
                        </div>
                        <input type="hidden" formControlName="plannedBenefitCurrencyCode">
                        <input type="hidden" formControlName="dateProjectWasCommittedTo">
                        <input type="hidden" formControlName="totalForecastCost">
                        <input type="hidden" formControlName="firstCommittedDeliveryDate">
                        <input type="hidden" formControlName="hleRequirementStatus">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" *ngIf="getDisplayRuleByKey('scope')">
                        <div class="form-group">
                            <label class="col col-form-label" for="scope">Scope</label>
                            <div class="col">
                              <textarea id="scope" formControlName="scope" type="text" class="form-control" style="resize:none"> </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Fulfillment Details (Service Now/ SR Tool) -->
    <div class="card mb-3 mt-3">
        <div class="card-header">
            <i (click)="isShowDetailsSrTool = !isShowDetailsSrTool" data-title="Fulfillment Details (Service Now/ SR Tool)" [ngClass]="isShowDetailsSrTool ? 'collapse-icon fa fa-minus-square-o text-muted mr-1' : 'collapse-icon fa fa-plus-square-o text-muted mr-1'"
                data-toggle="collapse" data-target="#collapseDetailsSrTool" aria-expanded="true" aria-controls="collapseDetailsSrTool"></i>
            <b>Fulfillment Details (Service Now/ SR Tool)
                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.fulfillmentDetailsSrTool}}"></i>
            </b>
        </div>
        <div class="collapse" id="collapseDetailsSrTool">
            <div class="card-body">
                <div class="row">
                    <!--left side -->
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col col-form-label" for="srOpportunityId">Opportunity ID
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.srOpportunityId}}"></i>
                            </label>
                            <div class="col">
                                <input id="srOpportunityId" formControlName="srOpportunityId" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="projectManagerServiceNow">Project Manager
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.projectManagerServiceNow}}"></i>
                            </label>
                            <div class="col">
                                <input id="projectManagerServiceNow" formControlName="projectManagerServiceNow" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <!--right side -->
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col col-form-label" for="projectNumber">Project number/ ID
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.serviceNowProjectNumber}}"></i>
                            </label>

                            <div class="col">
                                <input *ngIf="getDisplayRuleByKey('serviceNowProjectNumber')" id="projectNumber" 
                                    formControlName="projectNumber" type="text" class="form-control"
                                    (ngModelChange)="onFieldChange({ name: 'projectNumber', value: $event })">
                                <input *ngIf="!getDisplayRuleByKey('serviceNowProjectNumber')" type="text" class="form-control" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Opportunity Details -->
    <div class="card mb-3">
        <div class="card-header">
            <i (click)="isShowOpportunityDetails = !isShowOpportunityDetails" data-title="Opportunity Details" [ngClass]="isShowOpportunityDetails ? 'collapse-icon fa fa-minus-square-o text-muted mr-1' : 'collapse-icon fa fa-plus-square-o text-muted mr-1'"
                data-toggle="collapse" data-target="#collapseOpportunityDetails" aria-expanded="false" aria-controls="collapseOpportunityDetails"></i>
            <b>Opportunity Details
                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.opportunityDetailsPanel}}"></i>
            </b>
        </div>
        <div class="collapse" id="collapseOpportunityDetails">
            <div class="card-body">
                <div class="row" *ngIf="getDisplayRuleByKey('fetchOpportunityDetailsPanel')">
                    <!--left side -->
                    <div class="col-6">
                        <!--<div class="form-group">
                            <div class="checkbox col">
                                <input type="checkbox" id="doYouWantToMapTheCommitmentWithECrmOpportunity" 
                                    formControlName="doYouWantToMapTheCommitmentWithECrmOpportunity"
                                    (change)="onCanMapTheCommitmentWithOpportunityChange()">
                                <label class="col-form-label pl-4" for="doYouWantToMapTheCommitmentWithECrmOpportunity">Do you want to map the commitment with eCRM Opportunity? </label>
                            </div>
                        </div>-->
                        <div class="form-group" *ngIf="getDisplayRuleByKey('searchOpportunityId')">
                            <label class="col col-form-label" for="searchOpportunityId">Opportunity ID
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.searchOpportunityId}}"></i>
                            </label>
                            <div class="col">
                                <input type="text" id="searchOpportunityId" formControlName="searchOpportunityId" class="form-control" (keyup)="onSearchOpportunityIdChange($event)">
                            </div>
                        </div>
                        <div class="form-group" *ngIf="getDisplayRuleByKey('getECrmOpportunity')">
                            <div class="col">
                                <button type="button" class="btn btn-primary btn-md buttonclick" (click)="fetchEcrmOpportunityDetails()">Get eCRM Opportunity</button>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="getDisplayRuleByKey('opportunityErrorMessage')">
                            <div class="col">
                                <span style="color: red;">{{this.apiEcrmDataErrorMessage}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6"></div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col col-form-label" for="eCrmAccountId">eCRM Account ID</label>
                            <div class="col">
                                <input id="eCrmAccountId" formControlName="eCrmAccountId" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityStage">Opportunity Stage</label>
                            <div class="col">
                                <input id="opportunityStage" formControlName="opportunityStage" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityType">Opportunity Type</label>
                            <div class="col">
                                <input id="opportunityType" formControlName="opportunityType" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityCreatedDate">Opportunity Created Date</label>
                            <div class="col">
                                <input id="opportunityCreatedDate" type="date" formControlName="opportunityCreatedDate"
                                [ngClass]="generateInputClass('opportunityCreatedDate')"
                                class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="getDisplayRuleByKey('opportunityDetailsRightPanel')">
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityId">Opportunity ID</label>
                            <div class="col">
                                <input id="opportunityId" formControlName="opportunityId" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityOwnerName">Opportunity Owner Name
                                <i data-toggle="tooltip" class="fa fa-question-circle text-muted" ref="tooltip" title="{{tooltips.opportunityOwnerName}}"></i>
                            </label>
                            <div class="col">
                                <input id="opportunityOwnerName" formControlName="opportunityOwnerName" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityPlannedGoLiveDate">Planned Go Live Date</label>
                            <div class="col">
                                <input id="opportunityPlannedGoLiveDate" formControlName="opportunityPlannedGoLiveDate" type="date"
                                [ngClass]="generateInputClass('opportunityPlannedGoLiveDate')"
                                class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col col-form-label" for="opportunityClosedDate">Opportunity Closed Date</label>
                            <div class="col">
                                <input id="opportunityClosedDate" type="date" formControlName="opportunityClosedDate"
                                [ngClass]="generateInputClass('opportunityClosedDate')"
                                class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>

    <!-- Commitment Risk Status -->
    <div class="card mb-3">
        <div class="card-header">
            <b>Commitment Risk Status</b>
        </div>
        <div class="card-body">
            <div class="row">
                <!--left side -->
                <div class="col-9">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="checkbox col">
                                    <input type="checkbox" id="isRisk" formControlName="isRisk">
                                    <label class="col-form-label pl-4" for="isRisk">Please check, if commitment is At Risk. It will be highlighted on the dashboard. </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group" *ngIf="getDisplayRuleByKey('riskReason')">
                                        <label class="col col-form-label asterisk" for="riskReason">Risk Reason</label>
                                        <div class="col">
                                            <ng-select
                                                id="riskReason"
                                                formControlName="riskReason"
                                                [items]="riskReasonOptions" [virtualScroll]="true"
                                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                                bindValue="value" class="ng-select">
                                                <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                    let-index="index">
                                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="ng-value-label" title="{{getSelectedLabel(riskReasonOptions, item.value)}}">
                                                        {{getSelectedLabel(riskReasonOptions, item.value)}}
                                                    </span>
                                                </ng-template>
                                            </ng-select>
                                            <span class="field-error-message" *ngIf="getValidationError('riskReason')">{{getValidationError('riskReason')}}</span>
                                        </div>  
                                    </div>                                                                              
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group" *ngIf="getDisplayRuleByKey('riskDescription')">
                                        <label class="col col-form-label asterisk" for="riskDescription"> Please explain the risk.</label>
                                        <div class="col">
                                            <textarea id="riskDescription" formControlName="riskDescription" class="form-control" rows="3"></textarea>
                                            <span class="field-error-message" *ngIf="getValidationError('riskDescription')">{{getValidationError('riskDescription')}}</span>
                                        </div> 
                                    </div>
                                    <div class="form-group" *ngIf="getDisplayRuleByKey('isReviewed')">
                                        <div class="checkbox col">
                                            <input type="checkbox" id="isReviewed" formControlName="isReviewed">
                                            <label class="col-form-label pl-4" for="isReviewed">Is Reviewed</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="checkbox col">
                                            <input type="checkbox" id="isTracked" formControlName="isTracked">
                                            <label class="col-form-label pl-4" for="isTracked">Is Tracked</label>
                                        </div>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end fdl-2">
        <button type="button" (click)="cancel()" class="btn fdl-btn btn-secondary-light mr-auto">Cancel</button>
        <!-- Start: Creating a new Manual commitment -->
        <button *ngIf="!readOnly && !commitmentProjectKey" type="button" class="btn fdl-btn btn-primary-orange mr-2" 
            aria-label="Save" (click)="saveAsDraft()">
            Save
        </button>
        <button *ngIf="!readOnly && !commitmentProjectKey" type="button" class="btn fdl-btn btn-primary-orange mr-2" 
            aria-label="Save" (click)="submit()">
            Submit
        </button>
        <!-- End: Creating a new Manual commitment -->
        <!-- When editing an existing commitment -->
        <button *ngIf="!readOnly && commitmentProjectKey" type="button" class="btn fdl-btn btn-primary-orange mr-2" 
            aria-label="Save" (click)="submit()">
            Save
        </button>
    </div>
</form>
