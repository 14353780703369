import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';
import { ColumnPreferenceSetting } from '../models/column-preference-setting';
import { PermissionsService } from 'src/app/services/permissions.service';
import { IUser } from 'src/app/shared/user';
import { UserService } from 'src/app/services/user.service';
import * as Constants from "../constants/commitment-intake-constants";
import { ManageCommitmentListService } from '../services/manageCommitmentList.service';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { DateTimeService } from '../services/date-time.service';

@Component({
  selector: 'tr[app-commitment-intake-row]',
  templateUrl: './commitment-intake-row.component.html',
  styleUrls: ['./commitment-intake-row.component.css']
})
export class CommitmentIntakeRowComponent implements OnInit {
  @Input() public rowIndex: number = 0;
  @Input() public item: ICommitmentIntakeDto;
  @Input() public isPageTemplateAdmin: boolean = false;
  @Input() public originCommitments: Array<any> = [];
  @Input() public commitmentIntakeList: ICommitmentIntakeDto[];
  @Input() public columnPreferenceSettings: ColumnPreferenceSetting[] = [];
  @Input() public content: any = [];
  @Input() public disabledCustomCommitment: boolean = false;
  @Input() public disabledNonCustomCommitment: boolean = false;
  @Input() public selectedTabName: string;
  @Output() private onTrackingUpdated = new EventEmitter<ICommitmentIntakeDto>();
  @Output() private onRedirectToDetails = new EventEmitter();
  @Output() private onOpenDeleteModal = new EventEmitter();
  @Output() private onFieldChanged = new EventEmitter<{ row: ICommitmentIntakeDto, fieldName: string, childField: string }>();
  @Output() private onOpenMapFulfillment = new EventEmitter<{ projectDataSource: string, suggestion?: any }>();
  @Output() private oneViewClicked = new EventEmitter<any>();
  @Output() private updateUserClicked = new EventEmitter();
  @Output() private addUserClicked = new EventEmitter<any>();
  
  public isCCTAdmin = false;
  public isCCTAdminWriteAccess = false;
  public isAdmin = false;
  public currentIndex: number;
  public riskReasonOptions: any[] = [];
  public blueThemeMode = false;

  private readonly _unnecessaryProductNameCharacters = ['"', '[', ']'];
  private _zindexCounter = 0;
  private _userData: IUser;
  private _formFieldChangeCounter: number = 0;
  private readonly _disableFormFieldApiKeys = ['riskReason'];
  constructor(
    private userService: UserService,
    private permission: PermissionsService, 
    private _manageCommitmentService: ManageCommitmentListService,
    private _dateTimeService: DateTimeService) { 
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngOnInit(): void {
    this.riskReasonOptions = Constants.RiskReasonOptions;
    this._userData = this.userService.getUserListData();
    this.isCCTAdmin = this.permission.isCCTAdmin();    
    this.isCCTAdminWriteAccess = this.permission.isCCTAdminWriteAccess(this.isPageTemplateAdmin);
    this.isAdmin = this._userData.isAdmin;
  }

  public createdByLoggedInUser(item: ICommitmentIntakeDto): boolean {
    if (item.cretedById.toString() == this._userData.id) {
      return true;
    }
    return false;
  }
  
  public canDeleteCommitment(item: ICommitmentIntakeDto): boolean {
    if (this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel) || !this.hasRolesCanDeleteCommitment()) {
      return false;
    }

    const result = item.accessLevel === 'Write' && (this.isPageTemplateAdmin || (this.hasRolesCanDeleteCommitment()));
    // const result = item.accessLevel === 'Write' && this.hasRolesCanDeleteCommitment();
    return result;
  }

  public canGetOpportunitySuggestion(item: ICommitmentIntakeDto): boolean {
    if (item?.status == 'Fulfillment - Canceled' ||
      item?.status == 'Canceled' ||
      item?.status == 'Cancelled') {
      return false;
    }
    const result = environment.excludedDatasources.indexOf(item.datasource) === -1
      && !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel)
      && !item?.opportunityId
      && (this.permission.hasWriteAccessOnCommitmentIntake());

    return result;
  }

  public canGetProjectSuggestion(item: ICommitmentIntakeDto): boolean {
    if (item?.status == 'Fulfillment - Canceled' ||
      item?.status == 'Canceled' ||
      item?.status == 'Cancelled') {
      return false;
    }

    const result = item.datasource !== 'clarity'
      && !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel)
      && !item.projectNumber
      && this.permission.hasWriteAccessOnCommitmentIntake();

    return result;
  }

  public hasRolesCanDeleteCommitment(): boolean {
    const result = this.permission.hasWriteAccessOnCommitmentIntake();
    return result;
  }

  public hasRolesCanTrackOrMarkAsRiskCommitment(): boolean {
    const result = this._manageCommitmentService.hasRoleCanEditCommitment();
    return result;
  }


  public showTitleDataForMappedCommitmentDetails(item: ICommitmentIntakeDto): string {
    // TODO: test
    let text = '';
    let projectOrOpportunityId = null;
    if (item.datasource === 'ecrm') {
      text = 'opportunity';
      projectOrOpportunityId = item.commitmentIntakeMappedDetails.projectNumber;
    } else if (item.datasource === 'clarity') {
      text = 'project';
      projectOrOpportunityId = item.commitmentIntakeMappedDetails.opportunityId;
    }
    return `The ${text} ${projectOrOpportunityId} is already mapped to an existing commitment '${item.commitmentIntakeMappedDetails.commitmentTitle}'`;
  }

  onFieldChanging(row: ICommitmentIntakeDto, fieldName: string, childField = '') {
    this.onFieldChanged.emit({ row, fieldName, childField });
  }

  formFieldChanged(row: ICommitmentIntakeDto, parentField: string = '', childField: string = '') {
    const formFieldKey = `${parentField}OriginalValue`;
    if (row[formFieldKey] !== row[parentField]) {
      this._formFieldChangeCounter = this._formFieldChangeCounter + 1;
    }
    else {
      this._formFieldChangeCounter = this._formFieldChangeCounter - 1;
    }

    if (parentField !== '' && childField !== '') {
      const childFormFieldKey = `${childField}OriginalValue`;
      if (row[childFormFieldKey] !== row[childField]) {
        this._formFieldChangeCounter = this._formFieldChangeCounter + 1;
      }
    }

    this.onTrackingUpdated.emit(row);
  }

  public shouldDisableDuetoReadOnlyRoles(item: ICommitmentIntakeDto, formFieldApiKey: string): boolean {

    const isCancelled = this._manageCommitmentService.isCancelledCommitment(item);
    if (isCancelled == true && item.riskReason != '') {
      return true;
    }

    const result = (this.isDisableByColumnKey(formFieldApiKey)
      && (this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel)
        || !this._manageCommitmentService.hasRoleCanEditCommitment()));

    return result;
  }

  // Disable IsRisk if commitment is mapped or any is ReadOnly or commitment is Cancelled
  public shouldDisableIsRiskCheckbox(item: ICommitmentIntakeDto, col: ColumnPreferenceSetting): boolean {
    // CCT Admin (with write access) user should have the ability to uncheck/check is risk for only ecrm project
    if (this.selectedTabName.toLowerCase() == 'presale') {
      return true;
    }
    const isCCTAdminWriteAccess = this.permission.isCCTAdminWriteAccess(this.isPageTemplateAdmin);
    if (isCCTAdminWriteAccess) {
      return false;
    }
    else if (!isCCTAdminWriteAccess && item.accessLevel.toLowerCase() == 'write') {
      if (item.projectDataSource === 'ecrm') {
        return true;
      }
      else {
        return this._manageCommitmentService.isCancelledCommitment(item);
      }
    }
    else if (!isCCTAdminWriteAccess && item.accessLevel.toLowerCase() == 'read') {
      return true;
    }
    else {
      return true;
    }
  }
 
  public isDisableByColumnKey(columnKey: string): boolean {
    const result = this._disableFormFieldApiKeys.some(key => key === columnKey);

    return result;
  }

  public canView(item: ICommitmentIntakeDto): boolean {
    return !this.canViewEdit(item);
  }

  public canUpdateUserForCustom(item: ICommitmentIntakeDto): boolean {
    if (this.canViewEdit(item) && (item.isCustomCommitment || item.isCustomOneViewCommitment) && item.isTracked == true) {      
      return true;
    }
    return false;
  }

  public canViewEdit(item: ICommitmentIntakeDto): boolean {
    return !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel) && (this._manageCommitmentService.hasRoleCanEditCommitment());
  }

  public canEdit(): boolean {
    return this._userData.isAdmin;
  }

  public canMapProject(item: ICommitmentIntakeDto): boolean {
    if (item.datasource == 'servicenow' || item.datasource == 'clarity' || item.datasource == 'cims') {
      return false;
    }

    if (this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel)) {
      return false;
    }

    if (item?.projectNumber) {
      return false;
    }

    if (this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin)) {
      return true;
    }

    return false;
  }

  public shouldDisableIsTrackCheckbox(item: ICommitmentIntakeDto, col: ColumnPreferenceSetting): boolean {
    return this._manageCommitmentService.shouldDisableIsTrackCheckbox(item, col, this.originCommitments, this.isPageTemplateAdmin);
  }

  public addZIndex(i: number): void {
    document.getElementById('actions-' + i).style.zIndex = (++this._zindexCounter).toString();
  }

  public removeUnnecessaryCharacters(key: string, value: any): string {
    if (key !== 'products' || !value) return value;

    const result = this.filterLetters(value.toString(), this._unnecessaryProductNameCharacters);
    return result;
  }

  private filterLetters(str, lettersToRemove) {
    lettersToRemove.forEach((letter) => {
      str = str.replaceAll(letter, '');
    });

    return str;
  }
  
  public formatDateTime(value, format, locale): any {
    const isValidDate = this._dateTimeService.IsValidDate(value);
    if (!value || value === 'To Be Scheduled' || !isValidDate) return value;
  
    const result = formatDate(value, format, locale);
    return result;
  }

  public convertTextForProduct(value: string): string {
    if (!value) {
      return '';
    }

    const productNames = JSON.parse(value);
    return productNames.join(',');
  }
 
  transformDataSourceString(text: string) {
    if (!text) { 
      return '';
    }
    if (text === 'eCRM' || text === 'ecrm') {
      return 'eCRM';
    }
    if (text === 'cims') {
      return 'CIMS';
    }

    return text.slice(0, 1).toUpperCase() + text.slice(1);
  }

  public openMapFulfillment(projectDataSource: string, suggestion?: any) {
    this.onOpenMapFulfillment.emit({ projectDataSource, suggestion});
  }

  public openDeleteModal(): void {
    this.onOpenDeleteModal.emit();
  }

  public onViewClicked(): void {
    this.onRedirectToDetails.emit();
  }

  oneViewClick(id: any): any {
    this.oneViewClicked.emit(id);
  }

  public openUpdateUserClicked(): void {
    this.updateUserClicked.emit();
  }

  public openAddUserClicked(item: ICommitmentIntakeDto): void {
    if (item.isCustomCommitment) {
      this.addUserClicked.emit(item);
    }    
  }
}
