import { Injectable } from '@angular/core';
import moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommitmentIntakeApiClarityData, CommitmentIntakeApiEcrmData, ICommitmentIntakeCreate, ICommitmentIntakeDetailsDto, ICommitmentIntakeDto, ICommitmentIntakeProductDto } from '../models/commitment-intake-d-t-o';
import { CommitmentTransformService } from './commitment-transform.service';
import { CommitmentValidator } from '../validators/commitment.validator';
import { IS_PAGE_TEMPLATE_ADMIN } from 'src/app/core/constants';
import { PermissionsService } from 'src/app/services/permissions.service';

@Injectable()
export class ManageCommitmentService {
  constructor(private fb: FormBuilder,
    private _transformService: CommitmentTransformService,
    private permission: PermissionsService) { }

  private _commitmentIntakeForm: FormGroup;
  
  private _formCreateVal: ICommitmentIntakeCreate;
  get createFormVal(): ICommitmentIntakeCreate {
    return this._formCreateVal;
  }
  set createFormVal(value: ICommitmentIntakeCreate) {
    this._formCreateVal = value;
  }

  private _isReadOnlyForm: boolean;
  get isReadOnlyForm(): boolean {
    return this._isReadOnlyForm;
  }
  set isReadOnlyForm(value: boolean) {
    this._isReadOnlyForm = value;
  }

   public initialCommitmentIntakeForm() {
    this._commitmentIntakeForm = this.fb.group({
      id: [null],
      datasource: [null, Validators.required],
      commitmentTitle: [null, Validators.required],
      commitmentDescription: [null, Validators.required],
      commitmentIntakeMappedDetails: [null],
      // clientDetails
      clientId: new FormControl({ value: null, disabled: true}),
      clientName: new FormControl({ value: null, disabled: true}, Validators.required),
      clientDunsNumber: [null, Validators.required],
      relationshipManagerName: [null],
      relationshipManagerEmailAddress: [null],
      newRM: [null],
      clientTemperature: new FormControl({ value: null, disabled: true}),
      // commitmentDetails
      buResponsibleForDeliveryIntakes: [null, [Validators.required, Validators.maxLength(50)]],
      organizations: [null, Validators.required],
      products: [null, Validators.required],
      lineOfBusinesses: [null],
      additionalProductNames: [null, CommitmentValidator.requiredIfDirtyValidator],
      commitmentType: [null, Validators.required],
      contractualPenalties: [null],
      commitmentSubType: [null, Validators.required],
      contractualPenaltyDescription: [null, Validators.required],
      commitmentDate: [null],
      commitmentAuthorizedBy: [null],
      rmAdditionalComments: [null],
      // commitmentStatus
      status: [null, Validators.required],
      originalGoLiveDate: new FormControl({ value: null, disabled: true}),
      plannedGoLiveDate: new FormControl({ value: null}),
      selectedPlannedGoLiveDateChangeReason: [null],
      plannedGoLiveDateChangeReasonOther: [null, CommitmentValidator.requiredIfDirtyValidator],
      liveDateChangeReasonRM: [null, CommitmentValidator.requiredIfDirtyValidator],
      plannedGoLiveDateChangeReason: [null],
      onHoldStakeholder: [null, CommitmentValidator.requiredIfDirtyValidator],
      onHoldReason: [null, CommitmentValidator.requiredIfDirtyValidator],
      onHoldReasonDescription: [null, CommitmentValidator.requiredIfDirtyValidator],
      isCommitmentBreached: [false],
      breachedReason: [null],
      // clarityFulfillmentDetails
      doYouWantToMapTheCommitmentWithClarityProject: [false],
      searchClarityProjectNumber: [null],
      clientTotalOfDaysChanged: [null],
      clientTotalOfDaysChangedCount: [null],
      fiservTotalOfDaysChanged: [null],
      fiservTotalOfDaysChangedCount: [null],
      projectNumber: new FormControl({ value: null, disabled: true}),
      workStatus: new FormControl({ value: null, disabled: true}),
      progress: new FormControl({ value: null, disabled: true}),
      statusUpdate: new FormControl({ value: null, disabled: true}),
      projectManager: new FormControl({ value: null, disabled: true}),
      resourceManagerLevel4: new FormControl({ value: null, disabled: true}),
      dateStatus: new FormControl({ value: null, disabled: true}),
      plannedBenefitCurrentYear: [null],
      lastDateChangeCommentary: [null],
      deliveryConfirmation: [null],
      projectStatus: new FormControl({ value: null, disabled: true}),
      clarityStatus: new FormControl({ value: null, disabled: true}),
      plannedCommittedStartDate: new FormControl({ value: null, disabled: true}),
      plannedCommittedDeliveryDate: new FormControl({ value: null, disabled: true}),
      displayPlannedDeliveryDate: new FormControl({ value: null, disabled: true}),
      statusUpdateDate: new FormControl({ value: null, disabled: true}),
      mc: new FormControl({ value: null, disabled: true}),
      mc1: new FormControl({ value: null, disabled: true}),
      deliveryOwner: new FormControl({ value: null, disabled: true}),
      resourceManagerLevel5: new FormControl({ value: null, disabled: true}),
      dateProjectWasCommittedTo: [null],
      totalForecastCost: [null],
      firstCommittedDeliveryDate: [null],
      hleRequirementStatus: [null],
      scope: new FormControl({ value: null, disabled: true}),
      // fulfillmentDetailsSrTool
      srOpportunityId: new FormControl({ value: null, disabled: true}),
      projectManagerServiceNow: new FormControl({ value: null, disabled: true}),
      // opportunityDetails
      opportunityId: [null],
      doYouWantToMapTheCommitmentWithECrmOpportunity: [false],
      searchOpportunityId: [null],
      eCrmAccountId: new FormControl({ value: null, disabled: true}),
      opportunityStage: new FormControl({ value: null, disabled: true}),
      opportunityType: new FormControl({ value: null, disabled: true}),
      opportunityCreatedDate: new FormControl({ value: null, disabled: true}),
      opportunityPlannedGoLiveDate: new FormControl({ value: null, disabled: true}),
      opportunityOwnerName: new FormControl({ value: null, disabled: true}),
      opportunityClosedDate: new FormControl({ value: null }),
      // commitmentRiskStatus
      isRisk: [false],
      riskReason: [null, CommitmentValidator.requiredIfDirtyValidator],
      riskDescription: [null, CommitmentValidator.requiredIfDirtyValidator],
      isReviewed: new FormControl({ value: false, disabled: true}),
      isTracked: [false]
    });
  }

  set commitmentIntakeForm(value: FormGroup){
    this._commitmentIntakeForm= value;
  }

  get commitmentIntakeForm(){
    return this._commitmentIntakeForm;
  }

  public getDefaultValue(data: ICommitmentIntakeDetailsDto, fieldName: string) {
    switch(fieldName) {
      case 'datasource':
        return 'Manual';
      case 'commitmentType':
        return 'Implementation';
      case 'clientTemperature':
        if (data && data.datasource && (data.datasource == 'eCRM' || data.datasource == 'Clarity')) {
          return 'green';
        }
        return null;
      case 'products':
        if (data && data.datasource && data.datasource == 'eCRM') {
          data.products;
        }
        return null;
      case 'commitmentSubType':
        if (data && data.datasource && (data.datasource == 'eCRM' || data.datasource == 'Clarity')) {
          return 'Contractual';
        }
        return null;
      case 'commitmentDate':
        if (data && data.datasource && data.datasource == 'eCRM') {
          return data.opportunityCreatedDate;
        }
        return null;
      case 'commitmentAuthorizedBy':
        if (data && data.datasource && data.datasource == 'eCRM') {
          return data.opportunityOwnerName;
        }
        return null;
      case 'status':
        if (data && data.datasource && (data.datasource == 'eCRM' || data.datasource == 'Clarity')) {
          return 'In Progress';
        }
        return null;
      case 'dateStatus':
          if (!data || (!data.dateStatus && !data.projectNumber)) {
            return "Committed";
          }
          return null;
      case 'isRisk':
        return false;
      case 'isReviewed':
      case 'isTracked':
        return true;
    }
  }

  public isFieldDisabled(formRoot: FormGroup, fieldName: string) {
    const datasource = formRoot.get('datasource').value;
    const isTracked = formRoot.get('isTracked').value;
    const status = formRoot.get('status').value;
    if (status === 'Cancelled') {
      if (fieldName === 'isTracked') {
        return !isTracked;
      }
      return true;
    }

    if (datasource !== 'Manual') {
      switch(fieldName) {
        case 'commitmentTitle':
        case 'commitmentDescription':
        case 'rmAdditionalComments':
          return false;
        case 'isTracked':
          this.shouldDisableIsTrackCheckbox(formRoot);
        case 'isRisk':
        case 'riskReason':
        case 'riskDescription':
          return this.shouldDisableIsRiskCheckbox(formRoot);
        default:
          return true;
      }
    }
    
    switch(fieldName) {
      case 'isTracked':
        this.shouldDisableIsTrackCheckbox(formRoot);
      case 'isRisk':
      case 'riskReason':
      case 'riskDescription':
        return this.shouldDisableIsRiskCheckbox(formRoot);
      case 'projectNumber':
      case 'workStatus':
      case 'progress':
      case 'statusUpdate':
      case 'projectManager':
      case 'resourceManagerLevel4':
      case 'dateStatus':
      case 'projectStatus':
      case 'clarityStatus':
      case 'plannedCommittedStartDate':
      case 'plannedCommittedDeliveryDate':
      case 'displayPlannedDeliveryDate':
      case 'statusUpdateDate':
      case 'mc':
      case 'mc1':
      case 'deliveryOwner':
      case 'resourceManagerLevel5':
      case 'scope':
      case 'projectManagerServiceNow':
      case 'srOpportunityId':
      case 'eCrmAccountId':
      case 'opportunityStage':
      case 'opportunityType':
      case 'opportunityCreatedDate':
      case 'opportunityPlannedGoLiveDate':
      case 'opportunityId':
      case 'opportunityOwnerName':
      case 'opportunityClosedDate':
        return true;
      default:
        return false;
    }
  }

  // Always disable IsTrack if commitment is mapped
  //   For Req1: Affect to Cancelled status and UN-TRACK only. Apply for all data source except eCRM 
  //      - Only user write access can un-track Cancelled record which already tracked. 
  //      - For another thing keep the existing one.
  //   For Req2: Apply for eCRM only
  //      - Now, only CCT Admin write access can track / untrack eCRM commitments no matter status
  public shouldDisableIsTrackCheckbox(formRoot: FormGroup): boolean {
    const isTracked = formRoot.get('isTracked').value;
    const dataSource = formRoot.get('datasource').value;
    if (dataSource.toLowerCase() !== 'ecrm') {
      // For Req1
      if (this.isCanceled(formRoot) && isTracked) {
        // Only user write access can un-track canceled commitment
        const shouldDisable = !this.isCCTAdminWrite();
        return shouldDisable;
      }

      return this.isCanceled(formRoot);
    }
    
    // For Req2
    if (this.isCanceled(formRoot) && !isTracked) {
      return true;
    }
    
    const canTrackEcrm = this.isCCTAdminWrite();
    return !canTrackEcrm;
  }

  // Disable IsRisk if commitment is mapped or any is ReadOnly or commitment is Cancelled
  public shouldDisableIsRiskCheckbox(formRoot: FormGroup): boolean {
    const dataSource = formRoot.get('datasource').value;
    if (dataSource.toLowerCase() !== 'ecrm') {
      return this.isCanceled(formRoot);
    }
    
    // CCT Admin (with write access) user should have the ability to uncheck/check is risk for only ecrm project
    return !this.isCCTAdminWrite();
  }

  isCCTAdminWrite(): boolean {
    const isPageAdmin = localStorage.getItem(IS_PAGE_TEMPLATE_ADMIN) === 'true';
    const isCCTAdminWriteAccess = this.permission.isCCTAdminWriteAccess(isPageAdmin);
    return isCCTAdminWriteAccess;
  }

  isCanceled(formRoot: FormGroup): any {
    const status = formRoot.get('status').value;
    return !!status && status === 'Cancelled';
  }

  isMapped(formRoot: FormGroup): boolean {
    const mappedCommitmentDetails = formRoot.get('commitmentIntakeMappedDetails').value;
    const dataSource = formRoot.get('datasource').value;
    const opportunityId = formRoot.get('opportunityId').value;
    const projectNumber = formRoot.get('projectNumber').value;
    if ((dataSource.toLowerCase() === 'ecrm' && !!opportunityId)
      || (dataSource.toLowerCase() === 'clarity' && !!projectNumber
      || mappedCommitmentDetails)) {
        return true;
  }

    return false;
  }

  public isFieldDisplay = (formRoot: FormGroup, fieldName: string): boolean => {
    if (!formRoot) {
      return false;
    }

    switch(fieldName) {
      case 'buResponsibleForDeliveryIntakes':
        return !(formRoot.get('datasource').value === 'Manual');
      case 'organizations':
        return formRoot.get('datasource').value === 'Manual';
      case 'additionalProductNames':
        const products = formRoot.get('products').value as ICommitmentIntakeProductDto[];
        return products && products.some(x => x.productTitle.indexOf('Other') >= 0);
      case 'contractualPenaltyDescription':
        return formRoot.get('commitmentSubType').value === 'Estimated Credits';
      case 'contractualPenalties':
        return formRoot.get('commitmentSubType').value === 'Estimated Credits';
      case 'selectedPlannedGoLiveDateChangeReason':
        const plannedGoLiveDate = formRoot.get('plannedGoLiveDate').value;
        const originalGoLiveDate = formRoot.get('originalGoLiveDate').value;
        if (!plannedGoLiveDate || !originalGoLiveDate) {
          return false;
        }
  
        const parsedOriginalGoLiveDate = moment(originalGoLiveDate, 'YYYYMMDDTHHmmss').format('YYYY-MM-DD');
        const parsedPlannedGoLiveDate = moment(plannedGoLiveDate, 'YYYYMMDDTHHmmss').format('YYYY-MM-DD');
        return parsedOriginalGoLiveDate != parsedPlannedGoLiveDate;
      case 'liveDateChangeReasonRM':
      case 'plannedGoLiveDateChangeReason':
        return false;
      case 'plannedGoLiveDateChangeReasonOther':
        return formRoot.get('selectedPlannedGoLiveDateChangeReason').value === 'Other';
      case 'onHoldStakeholder':        
        return (formRoot.get('status').value === 'Fiserv - On Hold' || formRoot.get('status').value === 'Client - On Hold' || formRoot.get('status').value === 'On Hold');      
      case 'onHoldReason':
        return (formRoot.get('status').value === 'Fiserv - On Hold' || formRoot.get('status').value === 'Client - On Hold' || formRoot.get('status').value === 'On Hold');
      case 'onHoldReasonDescription':
        return formRoot.get('onHoldReason').value === 'Other';
      case 'breachedReason':
        const isCommitmentBreached = formRoot.get('isCommitmentBreached').value;
        return (isCommitmentBreached === 'true' || isCommitmentBreached === true);
      case 'clarityProjectNumber':  
        return formRoot.get('datasource').value.toLowerCase() !== 'servicenow';
      case 'serviceNowProjectNumber':  
        return formRoot.get('datasource').value.toLowerCase() === 'servicenow';
      case 'searchClarityProjectNumber':
      case 'fetchClarityDetailsButton':
        const doYouWantToMapTheCommitmentWithClarityProject = formRoot.get('doYouWantToMapTheCommitmentWithClarityProject').value;
        return doYouWantToMapTheCommitmentWithClarityProject;
      case 'fetchClarityDetailsPanel':
        return formRoot.get('datasource').value == 'Manual' || formRoot.get('datasource').value == 'ecrm';
      case 'plannedCommittedDeliveryDate':
        return false;
      case 'scope':  
        const projectNumber = formRoot.get('projectNumber').value;
        return !!projectNumber;
      case 'searchOpportunityId':
        const doYouWantToMapTheCommitmentWithECrmOpportunity = formRoot.get('doYouWantToMapTheCommitmentWithECrmOpportunity').value;
        return doYouWantToMapTheCommitmentWithECrmOpportunity;
      case 'fetchOpportunityDetailsPanel':
        const dataSource = formRoot.get('datasource').value;
        return dataSource == 'Manual' || dataSource == 'CIMS' || dataSource == 'Clarity';
      case 'getECrmOpportunity':
        const canMapTheCommitmentWithECrmOppurtunity = formRoot.get('doYouWantToMapTheCommitmentWithECrmOpportunity').value;
        return (canMapTheCommitmentWithECrmOppurtunity === 'true' || canMapTheCommitmentWithECrmOppurtunity === true);
      case 'riskReason':
        const isRisk = formRoot.get('isRisk').value;
        return isRisk;
      case 'riskDescription':
        return formRoot.get('riskReason').value === 'other';
      case 'isReviewed': 
        return false;
      case 'mc1':
        return formRoot.get('datasource').value == 'CIMS';
      default:
        return true;
    }
  };

  public transFormFormValue(fieldName: any, data: FormGroup | ICommitmentIntakeDetailsDto, fetchedApiData?: CommitmentIntakeApiEcrmData | CommitmentIntakeApiClarityData) {
    const value = this._transformService.transform(fieldName, data, fetchedApiData);
    if (value === null || value === undefined) {
      return null;
    }
    return value;
  }

  handleFormDisability(formRoot: FormGroup) {
    const rootFormKeys = Object.keys(formRoot.controls);
    rootFormKeys.forEach(rootFormKey => {
      this.handleFormFieldDisability(formRoot, rootFormKey);
    });
  }

  handleFormFieldDisability(rootForm: FormGroup, fieldName: string) {
    const isDisabled = this.isFieldDisabled(rootForm, fieldName);
    const currentFormControl = rootForm.get(fieldName);
    if (!currentFormControl) {
      return;
    }

    if (this._isReadOnlyForm) {
      return;
    }

    if (isDisabled && !currentFormControl.disabled) {
      currentFormControl.disable();
    }
    else if (!isDisabled && currentFormControl.disabled) {
      currentFormControl.enable();
    }
  }

  distinctArray<T>(srcArray: any[], key: string) {
    const distinctedIds = new Set(srcArray.map(r => r[key]));
    const unique = Array.from(distinctedIds).map(id => {
      return srcArray.find(r => r[key] === id);
    }) as T[];

    return unique;
  }
}
