import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { CommitmentIntakeApiClarityData, CommitmentIntakeApiEcrmData, ICommitmentIntakeDetailsDto } from '../models/commitment-intake-d-t-o';
import { ProductService } from 'src/app/product/product.service';
import { OrganizationService } from 'src/app/admin/manageOrganization/organization.service';
import { ManageCommitmentService } from '../services/manageCommitment.service';
import { LandingService } from 'src/app/services/landing.service';
import { Subscription } from 'rxjs';
import * as Constants from "../constants/commitment-intake-constants";
declare var $: any;
import { take } from 'rxjs/operators';
import { ManageCommitmentListService } from '../services/manageCommitmentList.service';
import { DateTimeService } from '../services/date-time.service';
@Component({
  selector: 'app-commitment-intake-form',
  templateUrl: './commitment-intake-form.component.html',
  styleUrls: ['./commitment-intake-form.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CommitmentIntakeFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() commitmentProjectKey: string;
  @Input() commitmentForm: FormGroup;
  @Input() originalCommitmentDetails: ICommitmentIntakeDetailsDto;
  @Input() readOnly: boolean;
  @Input() isSubmitted: boolean;
  @Input() isSaveAsDraft: boolean;

  @Output() onSaved = new EventEmitter<any>();
  @Output() onBackToList = new EventEmitter<any>();

  // Dropdown values
  productOptions = [];
  buResponsibleForDeliveryOptions = [];
  organizationOptions = [];
  commitmentTypeOptions = [];
  commitmentSubTypeOptions = [];
  statusOptions = [];
  plannedGoLiveDateChangeReasonOptions = [];
  onHoldStakeholderOptions = [];
  onHoldReasonOptions = [];
  riskReasonOptions: any[] = [];
  
  public apiClarityDataErrorMessage?: string;
  public apiEcrmDataErrorMessage?: string;
  public tooltips = Constants.tooltips;

  public isShowFulfillmentDetails = false;
  public isShowDetailsSrTool = false;
  public isShowOpportunityDetails = false;

  private _productSuggestionSubscription: Subscription;
  private _organizationSuggestionSubscription: Subscription;
  private _clarityDetailsSubscription: Subscription;
  private _ecrmOpportunitySubscription: Subscription;
  private _formFieldSubscriptions: Subscription[] = [];
  private _apiClarityData?: CommitmentIntakeApiClarityData;
  private _apiEcrmData?: CommitmentIntakeApiEcrmData;
  
  constructor(private _productService: ProductService,
    private _dateTimeService: DateTimeService,
    private _organizationService: OrganizationService,
    private _manageCommitmentService: ManageCommitmentService,
    private _manageCommitmentListService: ManageCommitmentListService,
    private _landingService: LandingService) {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly && changes.readOnly.currentValue) {
      this.commitmentForm.disable();
    }
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("#commitmentForm").tooltip({ selector: '[data-toggle=tooltip]', placement: 'right', html: true });
    });
    this.onFormInitialChange();
    this.commitmentTypeOptions = Constants.CommitmentTypeOptions;
    this.commitmentSubTypeOptions = Constants.CommitmentSubTypeOptions;
    this.statusOptions = Constants.StatusOptions;
    this.plannedGoLiveDateChangeReasonOptions = Constants.plannedGoLiveDateChangeReasonOptions;
    this.onHoldStakeholderOptions = Constants.OnHoldStakeholderOptions;
    this.onHoldReasonOptions = Constants.OnHoldReasonOptions;
    this.riskReasonOptions = Constants.RiskReasonOptions;
    this._productSuggestionSubscription = this._productService.getAllSuggestions().subscribe((response) => {
      if (!response || response.length === 0) {
        return;
      }

      this.productOptions = response.map(p => {
        return {
          productTitle: p.title,
          productId: p.id
        };
      });
    });

    this._organizationSuggestionSubscription = this._organizationService.getAllSuggestions().subscribe((response) => {
      if (!response || response.length === 0) {
        return;
      }

      this.organizationOptions = [...response];
      const mdmbuMasterIds = new Set(response.map(r => r.mdmbuMasterId));
      const buResponsibleForDeliveryIntakes = Array.from(mdmbuMasterIds).map(id => {
        return response.find(r => r.mdmbuMasterId === id);
      });
      this.buResponsibleForDeliveryOptions = buResponsibleForDeliveryIntakes.map(p => {
        return {
          businessUnitTitle: p.buTitle
        };
      });
    });
  }

  // Handle the form controls changing on initially
  onFormInitialChange() {
    this._formFieldSubscriptions.push(
      this.commitmentForm.get('datasource').valueChanges.subscribe(change => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
      }),
      this.commitmentForm.get('relationshipManagerName').valueChanges.subscribe(change => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
      }),
      this.commitmentForm.get('clientDunsNumber').valueChanges.subscribe(change => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
      }),
      this.commitmentForm.get('newRM').valueChanges.subscribe(change => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
      }),
      this.commitmentForm.get('status').valueChanges.subscribe(change => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
        this.updateDependentFieldValidator('status');
      }),
      this.commitmentForm.get('isTracked').valueChanges.subscribe(_ => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
      }),
      this.commitmentForm.get('isRisk').valueChanges.subscribe(_ => {
        this._manageCommitmentService.handleFormDisability(this.commitmentForm);
      }),
      this.commitmentForm.get('commitmentType').valueChanges.pipe(take(1)).subscribe(change => {
        this.updateDependentFieldValidator('commitmentType');
      }),
      this.commitmentForm.get('commitmentSubType').valueChanges.pipe(take(1)).subscribe(change => {
        this.updateDependentFieldValidator('commitmentSubType');
      }),
      this.commitmentForm.get('datasource').valueChanges.pipe(take(1)).subscribe(change => {
        this.updateDependentFieldValidator('datasource');
      }),
      this.commitmentForm.get('id').valueChanges.subscribe(change => {
        const newOriginalGoLiveDate = this._manageCommitmentService.transFormFormValue('originalGoLiveDate', this.commitmentForm);
        this.commitmentForm.get('originalGoLiveDate').setValue(newOriginalGoLiveDate);
      })      
    );
  }

  // Handle the form controls changing after init
  onFieldChange(event: { name: string, value: any }) {
    this.handleRelatedFieldValueChange(event.name);
    this.updateDependentFieldValidator(event.name);
  }
  
  private handleRelatedFieldValueChange(fieldName: string) {
    switch(fieldName) {
      case 'commitmentTitle':
        this.handleFielChange('isTracked');
        break;
      case 'organizations':
        this.handleFielChange('buResponsibleForDeliveryIntakes');
        this.handleFielChange('lineOfBusinesses');
        break;
      case 'plannedCommittedDeliveryDate':
        this.handleFielChange('displayPlannedDeliveryDate');
        break;
      case 'status':
      case 'dateStatus':
      case 'plannedGoLiveDate':
        this.handleFielChange('displayPlannedDeliveryDate');
        if (fieldName === 'plannedGoLiveDate') {
          this.handleFielChange('originalGoLiveDate');
        }
        break;
      case 'plannedGoLiveDateChangeReasonOther':
        this.handleFielChange('plannedGoLiveDateChangeReason');
        break;
      default: 
        break;
    }
  }

  private handleFielChange(fieldName: string) {
    const newValue = this._manageCommitmentService.transFormFormValue(fieldName, this.commitmentForm);
    const currentValue = this.commitmentForm.get(fieldName)?.value;
    if (currentValue !== newValue) {
      this.commitmentForm.patchValue({[fieldName]: newValue});
    }
  }

  private updateDependentFieldValidator(fieldName: string) {
    switch(fieldName) {
      case 'status':
      case 'commitmentType': {
          const status = this.commitmentForm.get('status').value;
          const commitmentType = this.commitmentForm.get('commitmentType').value;
          const intakeStatuses = ['Intake - Approved', 'Fulfillment - In Progress', 'In Progress', 'Fulfillment - Complete', 'Completed', 'Commitment Breached'];
          const isPlannedGoLiveDateRequired = status && intakeStatuses.includes(status) && commitmentType && commitmentType != 'SLA Based';
          const plannedGoLiveDateControl = this.commitmentForm.get('plannedGoLiveDate');
          this.updateValidator(plannedGoLiveDateControl, isPlannedGoLiveDateRequired);
        }
        break;
      case 'commitmentSubType': {
          const commitmentSubType = this.commitmentForm.get(fieldName).value;
          const isContractualPenaltyDescriptionRequired = commitmentSubType === 'Estimated Credits';
          const contractualPenaltyDescriptionControl = this.commitmentForm.get('contractualPenaltyDescription');
          this.updateValidator(contractualPenaltyDescriptionControl, isContractualPenaltyDescriptionRequired);
        }
        break;
      case 'datasource': {
          const datasource = this.commitmentForm.get(fieldName).value;
          const isOrganizationsRequired = datasource.toLowerCase() === 'manual';
          const organizationsControl = this.commitmentForm.get('organizations');
          this.updateValidator(organizationsControl, isOrganizationsRequired);
        }
        break;
      default: 
        break;
    }
  }

  private updateValidator(control: AbstractControl, isRequered: boolean) {
    const hasRequired = control.hasValidator(Validators.required);
    if (isRequered && !hasRequired) {
      control.setValidators(Validators.required);
    }
    else if (!isRequered && hasRequired) {
      control.removeValidators(Validators.required);
    }

    control.updateValueAndValidity();
  }

  isFieldRequired(fieldName: string) {
    switch(fieldName) {
      case 'plannedGoLiveDate':
        const plannedGoLiveDateControl = this.commitmentForm.get(fieldName);
        return plannedGoLiveDateControl.hasValidator(Validators.required);
      default:
        return false;
    }
  }

  compareOrganization(item, selected) {
    return item.id === selected.id;
  }

  searchOrganizationFunc(term, item) {
    return item.title.toLowerCase().includes(term.toLowerCase());
  }

  compareBuesponsibleForDeliveryIntake(item, selected) {
    return item.mdmbuMasterId === selected.mdmbuMasterId;
  }
  
  searchBuResponsibleForDeliveryFunc(term, item) {
    return item.businessUnitTitle.toLowerCase().includes(term.toLowerCase());
  }

  compareProduct(item, selected) {
    return item.productTitle === selected.productTitle;
  }

  searchProductFunction(term, item) {
    return item.productTitle.toLowerCase().includes(term.toLowerCase());
  }

  getSelectedLabel(options: any[], value: string) {
    const selected = options.find(o => o.value === value);
    if(selected) {
      return selected.name;
    }
    return value;
  }

  getDisplayRuleByKey(key: string): boolean {
    switch(key) {
      case 'clarityDetailsRightPanel': {
        const dataSource = this.commitmentForm.get('datasource').value;
        if (dataSource == 'clarity') {
          return true;
        }

        if (dataSource != 'manual') {
          return false;
        }

        const doYouWantToMapTheCommitmentWithClarityProject = this.commitmentForm.get('doYouWantToMapTheCommitmentWithClarityProject').value;
        const searchClarityProjectNumber = this.commitmentForm.get('searchClarityProjectNumber').value;
        return doYouWantToMapTheCommitmentWithClarityProject && searchClarityProjectNumber
          && this._apiClarityData
          && this._apiClarityData.status == 'success'
          && this._apiClarityData.clarity[0].companyID != 'undefined' 
          && this._apiClarityData.clarity[0].companyID != '';
      }
      case 'clarityErrorMessage': {
        const doYouWantToMapTheCommitmentWithClarityProject = this.commitmentForm.get('doYouWantToMapTheCommitmentWithClarityProject').value;
        const searchClarityProjectNumber = this.commitmentForm.get('searchClarityProjectNumber').value;
        return doYouWantToMapTheCommitmentWithClarityProject
          && searchClarityProjectNumber
          && this._apiClarityData 
          && this._apiClarityData.status == 'failed';
      }
      case 'opportunityErrorMessage': {
        const doYouWantToMapTheCommitmentWithECrmOpportunity = this.commitmentForm.get('doYouWantToMapTheCommitmentWithECrmOpportunity').value;
        const searchOpportunityId = this.commitmentForm.get('searchOpportunityId').value;
        return doYouWantToMapTheCommitmentWithECrmOpportunity
          && searchOpportunityId
          && this._apiEcrmData
          && this._apiEcrmData.status == 'failed'
      }
      case 'opportunityDetailsRightPanel': {
        return true;
      }
      default:
        const canDisplay = this._manageCommitmentService.isFieldDisplay(this.commitmentForm, key);
        return canDisplay;
    }
  }

  // Start manage Clarity fulfillment details
  onCanMapTheCommitmentWithClarityProjectChange() {
    const doYouWantToMapTheCommitmentWithClarityProject = this.commitmentForm.get('doYouWantToMapTheCommitmentWithClarityProject').value;
    if (doYouWantToMapTheCommitmentWithClarityProject) {
      this._apiClarityData = null;
    }
  }

  onSearchClarityProjectNumberChange(event) {
    const searchValue = event.target.value;
    if (!searchValue) {
      this.resetClarityFulfillmentDetails();
    }

    if (this._apiClarityData && this._apiClarityData.clarity[0].investmentID === searchValue) {
      this.populateClarityFulfillmentDetails();
      return;
    }

    if (this.originalCommitmentDetails.projectKey === searchValue) {
      this._apiClarityData = null;
      this.populateClarityFulfillmentDetails(this.originalCommitmentDetails);
      return;
    }

    this.resetClarityFulfillmentDetails();
  }

  fetchClarityDetails() {
    const queryParam = Object.assign({}, {
      commitmentProjectKey: this.commitmentProjectKey,
    });

    const searchClarityProjectNumber = this.commitmentForm.get('searchClarityProjectNumber').value;
    this._clarityDetailsSubscription = this._landingService.getClarityProjectDetailsById(searchClarityProjectNumber, queryParam).subscribe(response => {
      this._apiClarityData = response;
      this.apiClarityDataErrorMessage = this._apiClarityData.errorMessage;
      this.populateClarityFulfillmentDetails();
    });
  }

  private populateClarityFulfillmentDetails(commitmentDetails?: ICommitmentIntakeDetailsDto) {
    const rootFormKeys = Object.keys(this.commitmentForm.controls);
    rootFormKeys.forEach(rootFormKey => {
      if (Constants.IgnoredClarityFieldsFromPopulating.includes(rootFormKey) || !Constants.ClarityFulfillmentDetailsFields.includes(rootFormKey)) {
        return;
      }

      const commitmentData = commitmentDetails ? commitmentDetails : this.commitmentForm;
      const currentValue = this._manageCommitmentService.transFormFormValue(rootFormKey, commitmentData, this._apiClarityData);
      this.commitmentForm.get(rootFormKey).setValue(currentValue);
    });
  }

  private resetClarityFulfillmentDetails() {
      const rootFormKeys = Object.keys(this.commitmentForm.controls);
      rootFormKeys.forEach(rootFormKey => {
        if (Constants.IgnoredClarityFieldsFromPopulating.includes(rootFormKey) || !Constants.ClarityFulfillmentDetailsFields.includes(rootFormKey)) {
          return;
        }
        this.commitmentForm.get(rootFormKey).reset();
      });
  }

  // End manage Clarity fulfillment details

  // Start manage Ecrm Opportunity details
  onCanMapTheCommitmentWithOpportunityChange() {
    const doYouWantToMapTheCommitmentWithECrmOpportunity = this.commitmentForm.get('doYouWantToMapTheCommitmentWithECrmOpportunity').value;
    if (doYouWantToMapTheCommitmentWithECrmOpportunity) {
      this._apiEcrmData = null;
    }
  }

  onSearchOpportunityIdChange(event) {
    const searchValue = event.target.value;
    if (!searchValue) {
      this.resetEcrmOpportunityDetails();
    }

    if (this._apiEcrmData && this._apiEcrmData.eCRMOppurtunity[0].opportunity_ID === searchValue) {
      this.populateEcrmOpportunityDetails();
      return;
    }

    if (this.originalCommitmentDetails.projectKey === searchValue) {
      this._apiEcrmData = null;
      this.populateEcrmOpportunityDetails(this.originalCommitmentDetails);
      return;
    }

    this.resetEcrmOpportunityDetails();
  }

  fetchEcrmOpportunityDetails() {
    const searchOpportunityId = this.commitmentForm.get('searchOpportunityId').value;
    const queryParam = Object.assign({}, {
      commitmentProjectKey: this.commitmentProjectKey,
      eCRMOppurtunityId: searchOpportunityId
    });
    
    this._ecrmOpportunitySubscription = this._landingService.geteCRMOppurtunityDetailsById(queryParam).subscribe(response => {
      this._apiEcrmData = response;
      this.apiEcrmDataErrorMessage = this._apiEcrmData.errorMessage;
      this.populateEcrmOpportunityDetails();
    });
  }

  private populateEcrmOpportunityDetails(commitmentDetails?: ICommitmentIntakeDetailsDto) {
    const rootForms = Object.keys(this.commitmentForm.controls);
    rootForms.forEach(rootFormKey => {
      if (Constants.IgnoredEcrmFieldsFromPopulating.includes(rootFormKey) || !Constants.EcrmOpportunityDetailsFields.includes(rootFormKey)) {
        return;
      }

      const commitmentData = commitmentDetails ? commitmentDetails : this.commitmentForm;
      const currentValue = this._manageCommitmentService.transFormFormValue(rootFormKey, commitmentData, this._apiEcrmData);
      this.commitmentForm.get(rootFormKey).setValue(currentValue);
    });
  }

  private resetEcrmOpportunityDetails() {
      const rootForms = Object.keys(this.commitmentForm.controls);
      rootForms.forEach(rootFormKey => {
        if (Constants.IgnoredEcrmFieldsFromPopulating.includes(rootFormKey) || !Constants.EcrmOpportunityDetailsFields.includes(rootFormKey)) {
          return;
        }
        
        this.commitmentForm.get(rootFormKey).reset();
      });
  }
  // End manage Ecrm Opportunity details

  // Start saving
  submit(isSaveAsDraft: boolean = false) {
    this.isSaveAsDraft = isSaveAsDraft;
    const updatedData = {
      ...this.originalCommitmentDetails,
      ...this.commitmentForm.getRawValue()
    };
    const updatedDataKeys = Object.keys(updatedData);
    updatedDataKeys.forEach(updatedDataKey => {
      switch (updatedDataKey) {
        case 'commitmentDate':
        case 'originalGoLiveDate':
        case 'plannedGoLiveDate':
        case 'plannedCommittedDeliveryDate':
        case 'plannedCommittedStartDate':
        case 'statusUpdateDate':
        case 'opportunityCreatedDate':
        case 'opportunityPlannedGoLiveDate':
        case 'opportunityClosedDate':
        case 'estConversionInstallDateC':
          updatedData[updatedDataKey]= this._dateTimeService.toDate(updatedData[updatedDataKey]);
          break;
        default:
          break;
      }
    });
    
    this.onSaved.emit({ isSaveAsDraft: this.isSaveAsDraft, formData: updatedData });
  }

  generateInputClass(fieldName: string) {
    switch (fieldName) {
      case 'commitmentDate':
      case 'originalGoLiveDate':
      case 'plannedGoLiveDate':
      case 'plannedCommittedDeliveryDate':
      case 'plannedCommittedStartDate':
      case 'statusUpdateDate':
      case 'opportunityCreatedDate':
      case 'opportunityPlannedGoLiveDate':
      case 'opportunityClosedDate':
        if (!this.commitmentForm.get(fieldName).value) {
          return 'no-data';
        }
        break;
      default:
        break;
    }
  }

  saveAsDraft() {
    this.submit(true);
  }
  // End saving

  getValidationError(fieldName: string) {
    if (this.isSaveAsDraft && !Constants.createManualAsDraftRequiredFields.includes(fieldName)) {
      return null;
    }

    const formControl = this.generateFormControlByName(fieldName);
    if (!formControl || !formControl.hasValidator(Validators.required)) {
      return null;
    }

    const isChangeInvalid = formControl.invalid && (formControl.touched || formControl.dirty || this.isSubmitted);
    if (!isChangeInvalid) {
      return null;
    }
    
    return this.generateValidatorErrorMessage(fieldName);
  }

  private generateFormControlByName(fieldName: string) {
    switch (fieldName) {
      case 'commitmentTitle':
      case 'commitmentDescription':
      case 'clientName':
      case 'clientDunsNumber':
      case 'buResponsibleForDeliveryIntakes':
      case 'organizations':
      case 'commitmentType':
      case 'products':
      case 'additionalProductNames':
      case 'commitmentSubType':
      case 'contractualPenaltyDescription':
      case 'status':
      case 'plannedGoLiveDate':
      case 'selectedPlannedGoLiveDateChangeReason':
      case 'plannedGoLiveDateChangeReasonOther':
      case 'liveDateChangeReasonRM':
      case 'onHoldStakeholder':
      case 'onHoldReason':
      case 'riskReason':
      case 'riskDescription':
        return this.commitmentForm.get(fieldName);
      default:
        return null;
    }
  }

  private generateValidatorErrorMessage(fieldName: string) {
    switch (fieldName) {
      case 'commitmentTitle':
        return "Commitment Title is required";
      case 'commitmentDescription':
        return 'Commitment Description is required';
      case 'clientName':
        return 'Client Name is required';
      case 'clientDunsNumber':
        return 'Client Identifier (DUNS ID) is required';
      case 'buResponsibleForDeliveryIntakes':
      case 'organizations':
        return 'BU Responsible for Delivery is required';
      case 'commitmentType':
        return 'Commitment Type is required';
      case 'products':
        return 'Product/s is required';
      case 'additionalProductNames':
        return 'Product Name (Other) is required';
      case 'commitmentSubType':
        return 'Obligation Type is required';
      case 'contractualPenaltyDescription':
        return 'Estimated Credits Incurred Reason is required';
      case 'status':
        return 'Stage is required';
      case 'plannedGoLiveDate':
        return 'Planned Go Live Date is required';
      case 'selectedPlannedGoLiveDateChangeReason':
        return 'Planned Go Live Date Change Reason is required';
      case 'plannedGoLiveDateChangeReasonOther':
        return 'Planned Go Live Date Change Reason (Other) is required';
      case 'liveDateChangeReasonRM':
        return 'Go Live Date Change Reason is required';
      case 'onHoldStakeholder':
        return 'On Hold Stakeholder is required';
      case 'onHoldReason':
        return 'On Hold Reason is required';
      case 'riskReason':
        return 'Risk Reason is required';
      case 'riskDescription':
        return 'Risk Description is required';
      default:
        return null;
    }
  }

  public getFulfillmentDetailsTitle() {
    const datasource = this.commitmentForm.get('datasource').value;
    if (datasource.toLowerCase() === 'cims') {
      return 'Fulfillment Details (CIMS)';
    }
    return 'Fulfillment Details (Clarity)';
  }

  cancel(): void {
    this.onBackToList.emit();
  }

  ngOnDestroy(): void {
    this._formFieldSubscriptions?.forEach(subscription => {
      subscription?.unsubscribe();
    });
    this._productSuggestionSubscription?.unsubscribe();
    this._organizationSuggestionSubscription?.unsubscribe();
    this._clarityDetailsSubscription?.unsubscribe();
    this._ecrmOpportunitySubscription?.unsubscribe();
    this.commitmentForm?.reset();
    if (!this._manageCommitmentListService.isFromViewCancelling) {
      this._manageCommitmentListService.commitmentIntakePage = null; 
    }
  }
  
}
